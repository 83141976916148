import React from 'react'
import { connect } from 'react-redux'
import { Radio, Switch, Button, message } from 'antd'
import {
  toggleCollapsedNav,
  onNavTypeChange,
  onNavStyleChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onSwitchTheme
} from 'redux/actions/Theme'
import { CopyOutlined } from '@ant-design/icons'
import ColorPicker from 'components/shared-components/ColorPicker'
import CopyToClipboard from 'react-copy-to-clipboard'
import NavLanguage from './NavLanguage'
import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_DARK
} from 'constants/ThemeConstant'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import utils from 'utils/index'

const colorOptions = [
  '#3e82f7',
  '#24a772',
  '#de4436',
  '#924aca',
  '#193550'
]

const ListOption = ({ name, selector, disabled, vertical }) => (
  <div className={`my-4 ${vertical ? '' : 'd-flex align-items-center justify-content-between'}`}>
    <div className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}>{name}</div>
    <div>{selector}</div>
  </div>
)

export const ThemeConfigurator = ({
  navType,
  sideNavTheme,
  navCollapsed,
  topNavColor,
  headerNavColor,
  locale,
  currentTheme,
  toggleCollapsedNav,
  onNavTypeChange,
  onNavStyleChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onSwitchTheme
}) => {
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const isCollapse = navCollapsed

  const { switcher, themes } = useThemeSwitcher()

  const toggleTheme = (isChecked) => {
    onHeaderNavColorChange('')
    const changedTheme = isChecked ? 'dark' : 'light'
    onSwitchTheme(changedTheme)
    switcher({ theme: themes[changedTheme] })
  }

  const ontopNavColorClick = (value) => {
    onHeaderNavColorChange('')
    const { rgb } = value
    const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    const hex = utils.rgbaToHex(rgba)
    onTopNavColorChange(hex)
  }
  const onHeaderNavColorClick = (value) => {
    const { rgb } = value
    const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    const hex = utils.rgbaToHex(rgba)
    onHeaderNavColorChange(hex)
  }

  const onNavTypeClick = (value) => {
    onHeaderNavColorChange('')
    if(value === NAV_TYPE_TOP) {
      onTopNavColorChange(colorOptions[0])
      toggleCollapsedNav(false)
    }
    onNavTypeChange(value)
  }

  const genCopySettingJson = (configState) => JSON.stringify(configState, null, 2)

  return (
    <>
      <div className='mb-5'>
        <h4 className='mb-3 font-weight-bold'>Navigation</h4>
        {
          isNavTop ?
            <ListOption
              name='Top Nav Color:'
              selector={
                <ColorPicker color={topNavColor} colorChange={ontopNavColorClick} />
              }
              vertical />            :
            <ListOption
              name='Header Nav Color:'
              selector={
                <ColorPicker color={headerNavColor} colorChange={onHeaderNavColorClick} />
              }
              vertical />
        }

        <ListOption
          name='Navigation Type:'
          selector={
            <Radio.Group
              onChange={e => onNavTypeClick(e.target.value)}
              size='small'
              value={navType}>
              <Radio.Button value={NAV_TYPE_SIDE}>Side</Radio.Button>
              <Radio.Button value={NAV_TYPE_TOP}>Top</Radio.Button>
            </Radio.Group>
          } />
        <ListOption
          disabled={isNavTop}
          name='Side Nav Color:'
          selector={
            <Radio.Group
              disabled={isNavTop}
              onChange={e => onNavStyleChange(e.target.value)}
              size='small'
              value={sideNavTheme}>
              <Radio.Button value={SIDE_NAV_LIGHT}>Light</Radio.Button>
              <Radio.Button value={SIDE_NAV_DARK}>Dark</Radio.Button>
            </Radio.Group>
          } />
        <ListOption
          disabled={isNavTop}
          name='Side Nav Collapse:'
          selector={
            <Switch
              checked={isCollapse}
              disabled={isNavTop}
              onChange={() => toggleCollapsedNav(!navCollapsed)} />
          } />
        <ListOption
          name='Dark Theme:'
          selector={
            <Switch checked={currentTheme === 'dark'} onChange={toggleTheme} />
          } />
      </div>
      <div className='mb-5'>
        <h4 className='mb-3 font-weight-bold'>Locale</h4>
        <ListOption
          name='Language:'
          selector={
            <NavLanguage configDisplay />
          } />
      </div>
      <div>
        <CopyToClipboard
          onCopy={() => message.success('Copy Success, please paste it to src/configs/AppConfig.js THEME_CONFIG variable.')}
          text={genCopySettingJson({ currentTheme, headerNavColor, locale, navCollapsed, navType, sideNavTheme, topNavColor })}>
          <Button block icon={<CopyOutlined />}>
            <span>Copy Setting</span>
          </Button>
        </CopyToClipboard>
      </div>
    </>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme } =  theme

  return { currentTheme, headerNavColor, locale, navCollapsed, navType, sideNavTheme, topNavColor }
}

const mapDispatchToProps = {
  onHeaderNavColorChange,
  onNavStyleChange,
  onNavTypeChange,
  onSwitchTheme,
  onTopNavColorChange,
  toggleCollapsedNav
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeConfigurator)
