import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Vipealo proveedores'
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/'
export const AUTH_PREFIX_PATH = '/auth'

export const THEME_CONFIG = {
  currentTheme  : 'light',
  headerNavColor: '',
  locale        : 'es',
  mobileNav     : false,
  navCollapsed  : false,
  navType       : NAV_TYPE_SIDE,
  sideNavTheme  : SIDE_NAV_LIGHT,
  topNavColor   : '#3e82f7'
}
