import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route component={lazy(() => import('./home'))} path={'/inicio'} />
        <Route component={lazy(() => import('./bookings'))} path={'/reservas'} />
        <Route component={lazy(() => import('./tours'))} path={'/tours'} />
        <Route component={lazy(() => import('./reviews'))} path={'/reviews'} />
        <Route component={lazy(() => import('./profile'))} path={'/profile'} />
        <Redirect from='/' to='/inicio' />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
