import api from 'api'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const getToken = () => {
  return localStorage.getItem('accessToken')
}

export const login = async ({ email, password }) => {
  const signIn = await api('POST', 'users', 'login', { email, password })

  if(signIn.success)
    localStorage.setItem('accessToken', signIn.token)

  return signIn
}

export const passwordRequest = async ({ data }) => {
  const signIn = await api('POST', 'users', 'passwordRequest', { data })

  return signIn
}

export const passwordUpdate = async ({ data, password }) => {
  const signIn = await api('POST', 'users', 'passwordUpdate', { data, password })

  return signIn
}

export const confirmCode = async ({ data, email }) => {
  const signIn = await api('POST', 'users', 'confirmCode', { data, email })

  return signIn
}

export const logout = async () => {
  try {
    const token = getToken()

    const signOut = await api('POST', 'users', 'logout', {}, token)

    if(signOut.success) {
      localStorage.setItem('accessToken', null)

      window.location.href = `${AUTH_PREFIX_PATH}/login`
    }
  } catch (error) {
    window.location.href = `${AUTH_PREFIX_PATH}/login`
  }
}
