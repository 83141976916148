import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  const { search:queryParams } = useLocation()

  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route component={lazy(() => import('./authentication/login'))} path={`${AUTH_PREFIX_PATH}/login`} />
        <Route component={lazy(() => import('./authentication/forgot-password'))} path={`${AUTH_PREFIX_PATH}/forgot-password`} />
        <Route component={lazy(() => import('./authentication/logout'))} path={`${AUTH_PREFIX_PATH}/logout`} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login${queryParams}`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)

