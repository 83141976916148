import axios from 'axios'

const api = async (method, endpoint, operation, data, token) =>
  new Promise((resolve, reject) => {
    const { REACT_APP_API } = process.env

    const op = operation === 'list' ? '' : operation

    axios({
      data,
      headers: {
        authorization: `Bearer ${token}`
      },
      method,
      url            : `${REACT_APP_API}/${endpoint}/${op}`,
      withCredentials: true
    })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

export default api
