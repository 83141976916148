import enLang from './entries/en_US'
import zhLang from './entries/zh_CN'
import frLang from './entries/fr_FR'
import jaLang from './entries/ja_JP'
import esLang from './entries/es_ES'

const AppLocale = {
  en: enLang,
  es: esLang,
  fr: frLang,
  ja: jaLang,
  zh: zhLang
}

export default AppLocale
