const FirebaseConfig = {
  apiKey           : 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
  appId            : '1:807555350717:web:145ba7c21af47203a2f7d4',
  authDomain       : 'emilus.firebaseapp.com',
  databaseURL      : 'https://emilus.firebaseio.com',
  measurementId    : 'G-8KE7HJB252',
  messagingSenderId: '807555350717',
  projectId        : 'emilus',
  storageBucket    : 'emilus.appspot.com'
}

export default FirebaseConfig

