import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  HIDE_LOADING
} from '../constants/Auth'

export const signIn = (user) => {
  return {
    payload: user,
    type   : SIGNIN
  }
}

export const authenticated = (token) => {
  return {
    token,
    type: AUTHENTICATED
  }
}

export const signOut = () => {
  return {
    type: SIGNOUT
  }
}

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS
  }
}

export const signUp = (user) => {
  return {
    payload: user,
    type   : SIGNUP
  }
}

export const signUpSuccess = (token) => {
  return {
    token,
    type: SIGNUP_SUCCESS
  }
}

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE
  }
}

export const signInWithGoogleAuthenticated = (token) => {
  return {
    token,
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED
  }
}

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK
  }
}

export const signInWithFacebookAuthenticated = (token) => {
  return {
    token,
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED
  }
}

export const showAuthMessage = (message) => {
  return {
    message,
    type: SHOW_AUTH_MESSAGE
  }
}

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE
  }
}

export const showLoading = () => {
  return {
    type: SHOW_LOADING
  }
}

export const hideLoading = () => {
  return {
    type: HIDE_LOADING
  }
}
