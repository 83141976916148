import {
  DashboardOutlined,
  AppstoreOutlined,
  ReadOutlined,
  LikeOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons'
import { AUTH_PREFIX_PATH } from './AppConfig'

const dashBoardNavTree = [ {
  breadcrumb: false,
  icon      : DashboardOutlined,
  key       : 'inicio',
  path      : '/inicio',
  submenu   : [],
  title     : 'Inicio'
},
{
  breadcrumb: false,
  icon      : UserOutlined,
  key       : 'profile',
  path      : '/profile',
  submenu   : [],
  title     : 'Perfil'
},
{
  breadcrumb: false,
  icon      : AppstoreOutlined,
  key       : 'reservas',
  path      : '/reservas',
  submenu   : [],
  title     : 'Reservas'
},
{
  breadcrumb: false,
  icon      : ReadOutlined,
  key       : 'tours',
  path      : '/tours',
  submenu   : [],
  title     : 'Tours'
},
{
  breadcrumb: false,
  icon      : LikeOutlined,
  key       : 'reviews',
  path      : '/reviews',
  submenu   : [],
  title     : 'Reviews'
},
{
  breadcrumb: false,
  icon      : LogoutOutlined,
  key       : 'logout',
  path      : `${AUTH_PREFIX_PATH}/logout`,
  submenu   : [],
  title     : 'Cerrar sesión'
} ]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig
