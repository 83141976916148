import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Menu, Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import Logo from './Logo'
import NavPanel from './NavPanel'
import NavSearch  from './NavSearch'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme'
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant'
import utils from 'utils'

const { Header } = Layout

export const HeaderNav = props => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme } = props
  const [ searchActive, setSearchActive ] = useState(false)

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if(!isMobile)
      toggleCollapsedNav(!navCollapsed)
    else
      onMobileNavToggle(!mobileNav)
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = ()=> {
    if(!headerNavColor)
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')

    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if(isNavTop || isMobile)
      return '0px'

    if(navCollapsed)
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    else
      return `${SIDE_NAV_WIDTH}px`
  }

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className='nav' style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className='nav-left'>
            <Menu mode='horizontal'>
              {
                isNavTop && !isMobile ?
                  null                :
                  <Menu.Item key='0' onClick={() => {onToggle()}}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className='nav-icon' /> : <MenuFoldOutlined className='nav-icon' />}
                  </Menu.Item>
              }
            </Menu>
          </div>
          <div className='nav-right'>
            <NavPanel />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme } =  theme

  return { currentTheme, headerNavColor, mobileNav, navCollapsed, navType }
}

export default connect(mapStateToProps, { onMobileNavToggle, toggleCollapsedNav })(HeaderNav)
