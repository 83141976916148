import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  HIDE_LOADING
} from '../constants/Auth'

const initState = {
  loading    : false,
  message    : '',
  redirect   : '',
  showMessage: false,
  token      : localStorage.getItem(AUTH_TOKEN)
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading : false,
        redirect: '/',
        token   : action.token
      }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        loading    : false,
        message    : action.message,
        showMessage: true
      }
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message    : '',
        showMessage: false
      }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        loading : false,
        redirect: '/',
        token   : null
      }
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token  : action.token
      }
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false
      }
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token  : action.token
      }
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token  : action.token
      }
    }
    default:
      return state
  }
}

export default auth
